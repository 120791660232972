import React from 'react';
import { LinkItem } from '@local/components';
import { shape } from 'prop-types';
import useMediaWidth, { MEDIA_TYPE } from '@local/lib/hooks/useMediaWidth';
import { Image } from '@nintendo-of-america/component-library';

import { FeaturedItemsPropTypes } from '@local/lib/proptypes';
import * as S from './Banner.styles';

const Banner = ({ content }) => {
  const { asset } = content;
  const isTablet = useMediaWidth(MEDIA_TYPE.TABLET);
  const responsiveAsset =
    !isTablet && asset?.secondary?.assetPath ? asset.secondary : asset.primary;

  return (
    <S.BannerSection constrained>
      <LinkItem href={content.url} ariaLabel={content.heading}>
        <Image assetPath={responsiveAsset.assetPath} alt={asset.alt} />
      </LinkItem>
    </S.BannerSection>
  );
};

Banner.propTypes = {
  content: shape(FeaturedItemsPropTypes),
};

export default Banner;
